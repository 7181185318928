import type { ParentComponent } from "solid-js";
import { createContext, useContext } from "solid-js";
import { makePersisted } from "@solid-primitives/storage";
import { createStore } from "solid-js/store";

/**
 * Contains the mapping between SolidJS form field names and Drupal.
 * Keys are SolidJS, values are Drupal's.
 */
const mapping = {
  civility: "formCivility",
  first_name: "formFirstName",
  last_name: "formLastName",
  phone: "formPhone",
  phone_formatted: "formPhoneFormatted",
  email: "formEmail",
  location: "formLocation",
  city: "formCity",
  department: "formDepartment",
  region: "formRegion",
  postal_code: "formPostalCode",
  country: "formCountry",
  destination: "formDestination",
  rgpd_1: "rgpd1",
  // PTZ
  ZoneABC: "formPtzZoneABC",
  NbPersonne: "formPtzNbPersonne",
  CoutHorsFN: "formPtzCoutHorsFN",
  RevFiscRef: "formPtzRevFiscRef",
  LmnpParticipationMax: "formLmnpParticipationMax",
  PtzCoutHorsFN: "formPtzCoutHorsFN",
  PtzRevFiscRef: "formPtzRevFiscRef",
} as const;

type StorableFormFieldName = keyof typeof mapping;
type StorableFormFieldNameOld = (typeof mapping)[StorableFormFieldName];

function isStorableFormFieldName(
  name: StorableFormFieldName | string,
): name is StorableFormFieldName {
  return Object.keys(mapping).includes(name as StorableFormFieldName);
}

export type FormStorageInterface = [
  {
    values?: Partial<Record<StorableFormFieldName, string>>;
  },
  {
    getFormValuesFromStorage: () => object;
    setFormValuesToStorage: (
      newValues: Partial<Record<StorableFormFieldName, string>>,
    ) => void;
    contactInfoAreKnown: () => boolean;
  },
];

const FormStorageContext = createContext<FormStorageInterface>([
  {
    values: undefined,
  },
  {
    getFormValuesFromStorage: () => {
      return {};
    },
    setFormValuesToStorage: () => {
      return;
    },
    contactInfoAreKnown: () => {
      return false;
    },
  },
]);

export const FormStorageProvider: ParentComponent<{
  values?: object;
}> = (props) => {
  // eslint-disable-next-line solid/reactivity
  const [state, setState] = makePersisted(createStore({ values: {} }), {
      name: "form",
    }),
    store: FormStorageInterface = [
      state,
      {
        getFormValuesFromStorage() {
          return state.values ? state.values : {};
        },
        setFormValuesToStorage(
          newValues: Partial<Record<StorableFormFieldName, string>>,
        ) {
          //debugger;
          if (import.meta.env.DEV) {
            console.groupCollapsed("🏪 Updating localStorage");
            console.log("New values will be stored", newValues);
            console.log("Mapping new / old");
            console.dir(mapping);
            console.groupEnd();
          }

          const form: Partial<Record<StorableFormFieldName, string>> = {};

          Object.entries(newValues).forEach(([key, value]) => {
            if (Object.keys(mapping).includes(key)) {
              form[key as StorableFormFieldName] = value;

              // We keep updating the old storage schema so that old pages can still
              // benefit from locally stored values.
              if (isStorableFormFieldName(key)) {
                const oldKey: StorableFormFieldNameOld = mapping[key];
                // We cast all values as strings for the old storage engine
                localStorage.setItem(oldKey, `"${value}"` as string);
              }
            }
          });

          setState("values", form);
        },
        contactInfoAreKnown() {
          const form: Partial<Record<StorableFormFieldName, string>> =
            state.values ? state.values : {};
          return Boolean(
            form.civility &&
              form.first_name &&
              form.last_name &&
              form.phone &&
              form.email &&
              form.location &&
              form.destination,
          );
        },
      },
    ];

  return (
    <FormStorageContext.Provider value={store}>
      {props.children}
    </FormStorageContext.Provider>
  );
};

export function useFormStorageContext() {
  return useContext(FormStorageContext);
}
